<template>
  <v-app-bar absolute color="transparent" flat height="85">
    <v-container class="px-0 text-right d-flex">
      <v-img
        src="@/assets/logo/logo-euroespes-blanco.png"
        max-height="60"
        contain
        max-width="130"
      ></v-img>
      <v-spacer />
      <v-toolbar-title
        class="font-weight-light hidden-xs-only"
        v-text="title"
      />
      <v-btn
        v-for="(item, i) in items"
        :key="i"
        :to="item.to"
        min-height="48"
        min-width="40"
        text
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="item.icon"
        />
        <span class="hidden-sm-and-down" v-text="item.text" />
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "PagesCoreAppBar",
  components: {},
  data: () => ({
    items: [],
    titles: {}
  }),

  computed: {
    title() {
      return this.titles[this.$route.path];
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar {
  background: #00000000;
  backdrop-filter: blur(40px);
}
</style>
